import React from "react";
import { Link } from "react-router-dom";

class Main extends React.Component {
  render() {
    return (
      <section className="main">
        <img className="logo" src="/images/logo.png" alt="Logo" />
        <div className="bots">
          <Link to="/atlas/about">
            <img src="/images/atlas_portrait.png" id="atlas" alt="Atlas" />
            <h1>Atlas</h1>
          </Link>

          <Link to="/pbody/about">
            <img src="/images/pbody_portrait.png" id="pbody" alt="P-Body" />
            <h1>P-body</h1>
          </Link>
        </div>
        <div className="sources">
          <h2>Sources</h2>
          <ul>
            <li>
              <a href="https://theportalwiki.com">Portal Unofficial Wiki</a>
            </li>
            <li>
              <a href="https://store.steampowered.com/app/1840/Source_Filmmaker/">Source Filmmaker</a>
            </li>
            <li>
              <a href="https://store.steampowered.com/app/620/Portal_2/">Portal 2</a>
            </li>
            <li>
              <a href="https://www.thinkwithportals.com/">Official Portal 2 Website</a>
            </li>
          </ul>
        </div>
      </section>
    );
  }
}

export default Main;
