import React from "react";

class AboutAtlas extends React.Component {
  render() {
    return (
      <article>
        <p>
          <strong>P-body</strong>{" "}
          <em>
            (referred to as <strong>Orange</strong> by <strong>GLaDOS</strong>)
          </em>
          , is <strong>Atlas'</strong> tall and wiry partner in testing.
        </p>
        <p>
          <strong>P-body</strong> is played by the guest of the <strong>Co-op</strong> session; the player that receives
          an invitation, or the second player on a home console in split-screen mode.
        </p>
        <p>
          <strong>P-body</strong> is an orange eyed Core with a design similar to that of a Turret, and is portrayed to
          have somewhat less masculinity than in <strong>Atlas</strong>.
        </p>
      </article>
    );
  }
}

export default AboutAtlas;
