import { useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import Video, { Videos } from "./video";
import { ReactComponent as Home } from "../home.svg";

function Bot(props: { bot: "atlas" | "pbody"; selection: Videos; reset: () => void }) {
  const location = useLocation();

  const [defaultBlob, setDefaultBlob] = useState<string | null>(null);

  if (!defaultBlob) {
    fetch(`/videos/${props.bot}/default.mp4`)
      .then((r) => r.blob())
      .then((blob) => setDefaultBlob(URL.createObjectURL(blob)));
  }

  return (
    <section className={`bot ${props.bot}`}>
      <div className="names">
        <h1>{props.bot == "atlas" ? "Atlas" : "P-body"}</h1>
        <h1>
          <div>
            <Link to="/">
              <Home />
            </Link>
            <Link to={location.pathname.replace(props.bot, props.bot == "atlas" ? "pbody" : "atlas")}>
              {props.bot == "pbody" ? "Atlas" : "P-body"}
            </Link>
          </div>
        </h1>
      </div>
      <div className="subtitle">
        <h2>{location.pathname.endsWith("about") ? "About" : <Link to="about">About</Link>}</h2>
        <h2>{location.pathname.endsWith("skins") ? "Skins" : <Link to="skins">Skins</Link>}</h2>
        <h2>{location.pathname.endsWith("gestures") ? "Gestures" : <Link to="gestures">Gestures</Link>}</h2>
      </div>
      <Outlet />
      <Video bot={props.bot} selection={props.selection} defaultBlob={defaultBlob} reset={props.reset} />
    </section>
  );
}

export default Bot;
