import React from "react";
import { Videos } from "./video";

interface TableProps {
  bot: "atlas" | "pbody";
  onVideoChange: (to: Videos) => void;
}

class Table extends React.Component<TableProps> {
  render() {
    return (
      <table>
        <thead>
          <tr>
            <td>
              {/* <img src={`/images/${this.props.bot}.jpg`} /> */}
              Unique
            </td>
            <td>
              {/* <img src={`/images/${this.props.bot}_eye.jpg`} /> */}
              Universal
            </td>
            <td>
              {/* <img src="/images/taunts.png" /> */}
              Default
            </td>
            {/* <td>
              <img src="/images/staying_alive.png" />
              Team
            </td> */}
          </tr>
        </thead>

        <tbody>
          {[1, 2, 3, 4].map((number) => {
            return (
              <tr key={number}>
                <td onClick={() => this.props.onVideoChange(`dlc${number}` as any)}>
                  <img src={`/images/gestures/${this.props.bot}/unique_${number}.png`} />
                </td>
                <td onClick={() => this.props.onVideoChange(`dlc${4 + number}` as any)}>
                  <img src={`/images/gestures/${this.props.bot}/universal_${number}.png`} />
                </td>
                <td onClick={() => this.props.onVideoChange(`base${number}` as any)}>
                  <img src={`/images/gestures/${this.props.bot}/alone_${number}.png`} />
                </td>
                {/* <td className="unavailable" title="Unavailable">
                  <img src={`/images/gestures/${this.props.bot}/team_${number}.png`} />
                </td> */}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }
}

export default Table;
