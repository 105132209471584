import React from "react";

class AboutAtlas extends React.Component {
  render() {
    return (
      <article>
        <p>
          <strong>Atlas</strong>{" "}
          <em>
            (referred to as <strong>Blue</strong> by <strong>GLaDOS</strong>)
          </em>
          , is <strong>P-body's</strong> short and stout companion.
        </p>
        <p>
          <strong>Atlas</strong> is played by the host of the <strong>Co-op</strong> session; the player that sends an
          invitation.
        </p>
        <p>
          <strong>Atlas</strong> is a blue eyed Core with a basic design as a typical rounded Core, and is portrayed to
          have somewhat more masculinity in its programming than in <strong>P-body</strong>.
        </p>
      </article>
    );
  }
}

export default AboutAtlas;
