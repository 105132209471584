import React from "react";
import { Videos } from "./video";

class List extends React.Component<{ onVideoChange: (to: Videos) => void }> {
  render() {
    return (
      <ol>
        {[
          { title: "1: Default", name: "unknown" },
          { title: "2: Military", name: "green" },
          { title: "3: Moon", name: "moon" },
          { title: "4: Ninja", name: "black" },
          { title: "5: Starburst", name: "burst" },
          { title: "6: WCC", name: "wcc" },
        ].map((item) => {
          return (
            <li
              key={item.name}
              onClick={() => {
                this.props.onVideoChange((item.name == "unknown" ? "default" : item.name) as Videos);
              }}>
              <img src={`/images/skins/${item.name}.png`} />
              {item.title}
            </li>
          );
        })}
      </ol>
    );
  }
}

export default List;
