import React, { useState } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import { Videos } from "./views/video";

import Main from "./views/main";
import Bot from "./views/bot";
import AboutAtlas from "./views/aboutAtlas";
import AboutPbody from "./views/aboutPbody";
import List from "./views/list";
import Table from "./views/table";

function App() {
  const [selection, setSelection] = useState<Videos>("default");

  const location = useLocation();

  React.useEffect(() => {
    reset()
  }, [location]);

  function reset() {
    setSelection("default");
  }

  return (
    <TransitionGroup className="transitions">
      <CSSTransition key={location.key} timeout={200} classNames="fade">
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/atlas" element={<Bot bot="atlas" selection={selection} reset={reset} />}>
            <Route index element={<Navigate to="about" />} />
            <Route path="about" element={<AboutAtlas />} />
            <Route path="skins" element={<List onVideoChange={setSelection} />} />
            <Route path="gestures" element={<Table onVideoChange={setSelection} bot="atlas" />} />
          </Route>
          <Route path="/pbody" element={<Bot bot="pbody" selection={selection} reset={reset} />}>
            <Route index element={<Navigate to="about" />} />
            <Route path="about" element={<AboutPbody />} />
            <Route path="skins" element={<List onVideoChange={setSelection} />} />
            <Route path="gestures" element={<Table onVideoChange={setSelection} bot="pbody" />} />
          </Route>
        </Routes>
      </CSSTransition>
    </TransitionGroup>
  );
}

export default App;
