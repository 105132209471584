import React from "react";

export type Videos =
  | "default"
  | "green"
  | "moon"
  | "black"
  | "burst"
  | "wcc"
  | "dlc1"
  | "dlc2"
  | "dlc3"
  | "dlc4"
  | "dlc5"
  | "dlc6"
  | "dlc7"
  | "dlc8"
  | "base1"
  | "base2"
  | "base3"
  | "base4";

interface VideoProps {
  bot: "atlas" | "pbody";
  selection: Videos;
  defaultBlob: string | null;
  reset: () => void;
}

class Video extends React.Component<VideoProps> {
  render() {
    const looping =
      this.props.selection == "default" ||
      this.props.selection == "green" ||
      this.props.selection == "moon" ||
      this.props.selection == "black" ||
      this.props.selection == "burst" ||
      this.props.selection == "wcc";

    return (
      <div className="video">
        <video
          src={
            this.props.selection == "default" && this.props.defaultBlob
              ? this.props.defaultBlob
              : `/videos/${this.props.bot}/${this.props.selection}.mp4`
          }
          muted
          autoPlay
          loop={looping}
          onEnded={() => {
            if (!looping) this.props.reset();
          }}
        />
        <div className="spinner" />
      </div>
    );
  }
}

export default Video;
